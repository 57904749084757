<template>
    <div>
        <status-form
        :is-status-form-sidebar-active.sync="isStatusFormSidebarActive"
            :status-data="statusData"
            @refetch-data="fetchData" 
        />
        <b-card no-body>
            <div class="m-2">
                <b-row>
                    <b-col
                    >
                        <div class="d-flex align-items-center justify-content-end">
                            <b-button 
                                @click="addStatus"
                                v-ripple.400="'rgba(234, 84, 85, 0.15)'"
                                variant="outline-success">
                                <feather-icon icon="PlusIcon" />
                                Add
                            </b-button>
                        </div>
                        
                    </b-col>
                </b-row>
            </div>
            <div class="m-2">
                <vue-good-table
                    :columns="tableColumns"
                    :rows="items"
                    ref="refreceiptListTable"
                
                    :select-options="{
                        enabled: false,
                        selectOnCheckboxOnly: false, // only select when checkbox is clicked instead of the row
                        selectionInfoClass: 'custom-class',
                        selectionText: 'rows selected',
                        clearSelectionText: 'clear',
                        disableSelectInfo: true, // disable the select info panel on top
                        selectAllByGroup: true, // when used in combination with a grouped table, add a checkbox in the header row to check/uncheck the entire group
                    }"
                    :pagination-options="{
                            enabled: true,
                            perPage:perPage
                        }"
                
                    
                    >
                    <template
                        slot="table-row"
                        slot-scope="props"
                    >
                        <span 
                        v-if="props.column.field == 'is_active'"
                        class="text-nowrap"
                        >
                            <b-badge
                                pill
                                :variant="`light-${resolveUserStatusVariant(props.row.is_active)}`"
                                class="text-capitalize"
                            >
                                {{ resolveUserStatusnameVariant(props.row.is_active) }} 
                            </b-badge>
                        </span>
                        <span 
                        v-else-if="props.column.field == 'action'"
                        class="text-nowrap"
                        >
                        <b-button
                            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                            variant="flat-success"
                            class="btn-icon rounded-circle" 
                           
                            @click="updateStatus(props.row)" 
                        >
                            <feather-icon icon="Edit3Icon" />
                        </b-button>
                        <b-button
                            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                            variant="flat-danger"
                            class="btn-icon rounded-circle" 
                            v-if="props.row.id > 5"
                            @click="deleteconfirm(props.row.id)" 
                        >
                        <feather-icon icon="Trash2Icon" />
                        </b-button>
                
                      
                        <!--<b-button  v-ripple.400="'rgba(113, 102, 240, 0.15)'" class="ml-1" @click="deleteconfirm(data.item.id)" variant="outline-danger">Delete</b-button>-->
                        </span>
                    </template>
                    
                    <template
                        slot="pagination-bottom"
                        slot-scope="props"
                    >
                        <div class="d-flex justify-content-between flex-wrap">
                        <div class="d-flex align-items-center mb-0 mt-1">
                            <span class="text-nowrap ">
                            Items per page
                            </span>
                            <b-form-select
                            v-model="perPage"
                            :options="['10','20','50']"
                            class="mx-1"
                            @input="(value)=>props.perPageChanged({currentPerPage:value})"
                            />
                        
                        </div>
                        <div>
                            <b-pagination
                            v-model="currentPage"
                            :total-rows="totals"
                            :per-page="perPage"
                            first-number
                            last-number
                            align="right"
                            
                            class="mt-1 mb-0"
                            
                            >
                            <template #prev-text>
                                <feather-icon
                                icon="ChevronLeftIcon"
                                size="18"
                                />
                            </template>
                            <template #next-text>
                                <feather-icon
                                icon="ChevronRightIcon"
                                size="18"
                                />
                            </template>
                            </b-pagination>
                        </div>
                        </div>
                    </template>
                </vue-good-table>
            </div>
        </b-card>    
    </div>
</template>
<script>
import {
    BCard, BRow, BCol, BCollapse, BSidebar, BFormInput, BButton, BTable, BMedia, BAvatar, BLink,BImg,BFormSelect,
    BBadge, BDropdown, BDropdownItem, BPagination, BTooltip,VBModal,BFormGroup,BFormCheckbox,
    BInputGroup,BInputGroupPrepend,BInputGroupAppend,VBToggle,BFormDatepicker
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
///import FeatherIcon from '@/@core/components/feather-icon/FeatherIcon.vue'
import { VueGoodTable } from 'vue-good-table'
import store from '@/store'


import { ref,onUnmounted } from '@vue/composition-api'

import { formatDateTh,formatDateToMonthShort,getCommaDecimal } from '@core/utils/filter'

import vSelect from 'vue-select'
import router from '@/router'
import receiptStoreModule from '../receiptStoreModule'
import useStatusList from './useStatusList'
import StatusForm from './StatusForm.vue'
export default {
    components:{
        BCard, BRow, BCol, BCollapse, BSidebar, BFormInput, BButton, BTable, BMedia, BAvatar, BLink,BImg,BFormSelect,
    BBadge, BDropdown, BDropdownItem, BPagination, BTooltip,VBModal,BFormGroup,BFormCheckbox,
    BInputGroup,BInputGroupPrepend,BInputGroupAppend,VBToggle,BFormDatepicker,
    VueGoodTable,
    StatusForm

    },
    directives:{
        Ripple
    },
    setup(props, { emit }){
        const RECEIPT_STORE_MODULE_NAME = 'app-receipt';
        if (!store.hasModule(RECEIPT_STORE_MODULE_NAME)) store.registerModule(RECEIPT_STORE_MODULE_NAME, receiptStoreModule)
        onUnmounted(() => {
            if (store.hasModule(RECEIPT_STORE_MODULE_NAME)) store.unregisterModule(RECEIPT_STORE_MODULE_NAME)
        }); 
        const {
            t,
            refstatusListTable,
            fetchData,
            currentPage,
            totals,
            perPage,
            dataMeta,
            items,
            tableColumns,
            resolveUserStatusVariant,
            resolveUserStatusnameVariant,
            isStatusFormSidebarActive,
            statusData,
            addStatus,
            updateStatus,
            deleteData
           

        } = useStatusList(emit);

        return {
            t,
            refstatusListTable,
            fetchData,
            currentPage,
            totals,
            perPage,
            dataMeta,
            items,
            tableColumns,
            resolveUserStatusVariant,
            resolveUserStatusnameVariant,
            isStatusFormSidebarActive,
            addStatus,
            statusData,
            updateStatus,
            deleteData

        }


    },
    methods:{
        deleteconfirm(id){
            this.$bvModal
                .msgBoxConfirm('Please confirm that you want to delete Status ID.'+id, {
                title: 'Please Confirm',
                size: 'sm',
                okVariant: 'danger',
                okTitle: 'Yes',
                cancelTitle: 'No',
                cancelVariant: 'outline-secondary',
                hideHeaderClose: false,
                centered: true,
                })
                .then(value => {
                if(value === true){
                    this.deleteData(id)
                }
            
                //this.deleteData(id)
                })

        },
    }
    
}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-good-table.scss';
</style>