import { ref, watch, computed } from '@vue/composition-api'
import store from '@/store'
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import router from '@/router'
import { useUtils as useI18nUtils } from '@core/libs/i18n'
export default function useStatusList(emit) {
    const { t } = useI18nUtils();
    const toast = useToast();
    const refstatusListTable = ref();
    const isStatusFormSidebarActive = ref(false)
    const statusData = ref(null);
   
    const items = ref([]);
    const perPage = ref(10)
    const totals = ref(0)
    const currentPage = ref(1)
    const tableColumns = [
        { field: 'id', label: '#', sortable: false, class:'text-left' },
        { field: 'title', label: 'Status Name', sortable: false, class:'text-left' },
        { field: 'is_active', label: 'Active', sortable: false, class:'text-left' },
       
        { field: 'action', label: 'การจัดการ', sortable: false },
  
    
  
  
  
      ];
    const dataMeta = computed(() => {
      const localItemsCount = refstatusListTable.value ? refstatusListTable.value.localItems.length : 0
      return {
          from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
          to: perPage.value * (currentPage.value - 1) + localItemsCount,
          of: totals.value,
      }
  })
    watch([currentPage, perPage], () => {
        fetchData()
    })
    
    const fetchData = () => {
        items.value  = [];
        const offset = perPage.value*(currentPage.value-1);
          store
            .dispatch('app-receipt/fetchReceiptStatusData', {
             // patients_id:patients_id.value,
              offset:offset,
              limit:perPage.value,
             // keywords:searchQuery.vaule
            
                /* page: currentPage.value,
              sortBy: sortBy.value,
              sortDesc: isSortDirDesc.value,
              status: statusFilter.value,*/
            })
            .then(response => {
             
              const  statuses  = response.data.data
            
              //const total = response.data.total?response.data.total:cats.length;
              //callback(receipts)
              items.value = statuses;
               const total = response.data.total?response.data.total:statuses.length;
              
              totals.value = total
            
             // totalBlogs.value = total
            })
            .catch((error) => {
              toast({
                component: ToastificationContent,
                props: {
                  title: "Error fetching Category' list",
                  icon: 'AlertTriangleIcon',
                  variant: 'danger',
                },
              })
            })
         
      }
      fetchData();
      const addStatus = ()=>{
        isStatusFormSidebarActive.value =true
        statusData.value = {};
      }
      const updateStatus = (status)=>{
        isStatusFormSidebarActive.value =true
        statusData.value = status;

      }
      const resolveUserStatusVariant = status => {
       
        if (status) return 'success'
        if (!status) return 'secondary'
        return 'primary'
      }
      const resolveUserStatusnameVariant = status => {
      
          if (status) return 'Active'
          if (!status) return 'Inactive'
          return 'primary'
      }
      const deleteData = (id) => {
        //fetchDeleteProperty
        store.dispatch('app-receipt/deleteStatus',id).then(response=>{
          toast({
            component: ToastificationContent,
            props: {
              title: "Delete Success",
              icon: 'AlertTriangleIcon',
              variant: 'success',
            },
          })
          fetchData();
        }).catch(()=>{
          toast({
            component: ToastificationContent,
            props: {
              title: "Error Delete User",
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
    
        })
        //console.log('id', id);
        
      }
    return {
        t,
        refstatusListTable,
        fetchData,
        perPage,
        totals,
        currentPage,
        dataMeta,
        items,
        tableColumns,
        resolveUserStatusVariant,
        resolveUserStatusnameVariant,
      
        addStatus,
        isStatusFormSidebarActive,
        statusData,
        updateStatus,
        deleteData
    }
}